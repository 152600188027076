$cobalt: #0B2FCC;
$darker: #0e3bce;
$blackie: #03083a;
$silver: #6b6c77;
$npi: rgba(11,47,204,0.8);

body {
  background-color: white;
  text-align: center; }

header {
  width: 100vw;
  height: 100px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 1000;
  @media screen and (max-device-width: 1100px) {
    height: 60px; }
  a {
    text-decoration: none;
    color: $silver;
    &:active {
      color: $darker; }
    &:visited {
      color: $silver; } }
  ul {
    display: flex;
    list-style: none;
    flex-direction: row;
    li {
      color: $blackie;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 400;
      margin: 0 24px 0 0; } }
  .logo {
    font-size: 36px;
    margin: 0 0 0 24px;
    display: flex;
    @media screen and (max-device-width: 1100px) {
      font-size: 24px; }
    a {
      display: flex;
      color: $blackie;
      span {
        font-weight: 500;
        color: $cobalt; }
      small {
        font-size: 16px;
        font-weight: 500;
        margin: 5px 0 0 5px;
        color: $silver;
        @media screen and (max-device-width: 1100px) {
          margin: 2px 0 0 5px; } } } } }

.menu {
  display: none;
  @media screen and (max-device-width: 1100px) {
    display: flex; } }

nav {
  @media screen and (max-device-width: 1100px) {
    background-color: rgba(255,255,255,0.96);
    width: 100vw;
    height: 56px;
    margin: 60px 0 0 0;
    top: 0;
    display: flex;
    position: absolute;
    ul {
      display: flex;
      padding: 0 0 0 25px;
      li {
        flex-direction: column; } } } }

.active {
  font-weight: 500;
  color: $cobalt !important; }

.fix {
  max-width: 80vw;
  @media screen and (max-device-width: 1100px) {
    max-width: 90vw;
    padding: 40px 0; } }

.matrix {
  width: 100vw;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $darker;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  background-attachment: fixed;
  &:first-of-type {
    padding: 100px 0 0 0;
    @media screen and (max-device-width: 1100px) {
      padding: 60px 0 0 0; } }
  @media screen and (max-device-width: 1100px) {
    background-attachment: scroll;
    height: calc(100vh - 60px); }
  h1 {
    margin: 0;
    font-size: 64px;
    @media screen and (max-device-width: 1100px) {
      font-size: 32px; } }
  h2 {
    margin: 10px 0 0 0;
    font-size: 32px;
    @media screen and (max-device-width: 1100px) {
      font-size: 20px; } }
  p {
    // margin: 30px 0 0 0
    font-size: 20px;
    font-weight: 500;
    @media screen and (max-device-width: 1100px) {
      font-size: 17px;
      font-weight: 400; }
    a {
      color: #ffb500;
      text-decoration: none;
      &:visited {
        color: white; } } }
  .lite {
    font-weight: 300; } }

.dark-mode {
  color: white;
  h1, h2, p {
    color: white; }
  .secondary {
    color: #05c6fa; } }

.light-mode {
  color: gray;
  h1, h2, p {
    color: gray; }
  .secondary {
    color: #05c6fa; } }

.inicio {
  background-image: url('./images/3.jpg');
  @media screen and (max-device-width: 1100px) {
    background-image: url('./images/3-mobile.jpg'); } }

.intro-img {
  background-image: url('./images/5.jpg');
  @media screen and (max-device-width: 1100px) {
    background-image: url('./images/5-mobile.jpg'); } }

.portfolio {
  background-image: url('./images/8.jpg');
  @media screen and (max-device-width: 1100px) {
    background-image: url('./images/8-mobile.jpg'); } }

.contact {
  background-image: url('./images/6.jpg');
  @media screen and (max-device-width: 1100px) {
    background-image: url('./images/6-mobile.jpg'); } }

.programa {
  background-image: url('./images/2.jpg');
  @media screen and (max-device-width: 1100px) {
    background-image: url('./images/2-mobile.jpg'); } }

.tipos {
  background-image: url('./images/7.jpg');
  @media screen and (max-device-width: 1100px) {
    background-image: url('./images/7-mobile.jpg'); } }

.plan {
  background-image: url('./images/4.jpg');
  @media screen and (max-device-width: 1100px) {
    background-image: url('./images/4-mobile.jpg'); } }

.cursos {
  background-image: url('./images/1.jpg');
  @media screen and (max-device-width: 1100px) {
    background-image: url('./images/1-mobile.jpg'); } }

.matrix-program {
  width: 100vw;
  height: calc(100vh - 100px);
  display: flex;
  @media screen and (max-device-width: 1100px) {
    width: 100vw;
    height: auto; } }

.program-list {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-device-width: 1100px) {
    padding: 40px 0; } }

.program {
  width: 36vw;
  height: 36vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(19,2,187,0.6);
  border-radius: 4px;
  margin: 10px 10px 0 0;
  @media screen and (max-device-width: 1100px) {
    width: 100%;
    height: auto;
    margin: 10px 0 0 0;
    &:first-child {
      margin: 0; } }
  p {
    width: 90%;
    font-size: 16px;
    @media screen and (max-device-width: 1100px) {
      margin: 0 0 10px 0; } } }

.courses {
  p {
    font-weight: 400; }
  ul {
    list-style: none;
    display: flex;
    @media screen and (max-device-width: 1100px) {
      flex-direction: column;
      padding: 0; }
    li {
      background-color: rgba(19,2,187,0.6);
      margin: 0 10px 0 0;
      padding: 0 14px;
      border-radius: 4px;
      @media screen and (max-device-width: 1100px) {
        padding: 0;
        width: 100%;
        margin: 10px 0 0 0; } } } }


.hide-bg {
  animation: Hide-img normal  1 5s linear; }

@keyframes Hide-img {
  from {
    filter: blur(0); }
  to {
    filter: blur(60px); } }

.App {
  text-align: center; }

.App-logo {
  animation: App-logo-spin infinite 20s linear; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
